<template>
  <div>
    <waste-exchange-bed></waste-exchange-bed>
  </div>
</template>

<script>
import WasteExchangeBed from "@/components/ecologist/wasteExchange/wasteExchangeBed";

export default {
  name: "wasteExchangeBedView",
  components: { WasteExchangeBed },
};
</script>

<style scoped></style>
