<template>
    <div>
        <h2>Открытые заявки</h2>
        <div class="cards" :key="Bid.lots_id" v-for="Bid in ListBid">
      <el-card class="box-card" shadow="hover" v-if="Bid.lots.lotsId != null">
        <template #header>
          <div class="card-header">
            <el-col span="3">
              <div>Лот {{ Bid.lots.lotsId }}</div>
            </el-col>
            <el-col span="7">
              <div>
                    <div v-if="Bid.lots.disposal == 1">транспортом производителя</div>
                    <div v-else-if="Bid.lots.disposal == 2">транспортом переработчика</div>
                    <div v-else-if="Bid.lots.disposal == 3">
                      транспортом иной организации
                    </div>
                <div v-else>Тип транспорта не указан</div>
                    <!-- {{ delivery.find((obj) => obj.id === lot.disposal) }}
                    {{ delivery }} -->
              </div>
            </el-col>
            <el-col span="11">
              <span
              >Сбор предложений: {{ (10>new Date(Bid.lots.date_from).getDate()  ? '0':'')+new Date(Bid.lots.date_from).getDate()+'.'+(9>new Date(Bid.lots.date_from).getMonth() ? '0':'' )+(new Date(Bid.lots.date_from).getMonth()+1)+'.'+new Date(Bid.lots.date_from).getFullYear() }} &ndash;
                {{ (10>new Date(Bid.lots.date_to).getDate()  ? '0':'')+new Date(Bid.lots.date_to).getDate()+'.'+(9>new Date(Bid.lots.date_to).getMonth() ? '0':'' )+(new Date(Bid.lots.date_to).getMonth()+1)+'.'+new Date(Bid.lots.date_to).getFullYear() }}</span
              >
            </el-col>
            <el-col span="3">
              <el-button type="danger" @click="LotBidCansel(Bid)">Отменить заявку</el-button>
            </el-col>

          </div>
        </template>
        <el-row :gutter="10" class="test111">
          <el-col span="16" class="info">
            <div class="card">
              <div class="card-left">
                <div class="card-left-company"></div>
                <div class="card-left-location">{{ Bid.lots.adress }}</div>
              </div>
              <div class="card-left-waste-info">
                <div class="card-left-waste-info-name">
                  {{ (Bid.lots.waste_code==0?"":(Bid.lots.waste_code + " - "))  + Bid.lots.waste_name }}
                </div>
              </div>
              <div class="card-left-waste-quantity">
                <div class="card-left-waste-quantity-all-waste">
                  {{
                    Bid.lots.price_min +
                    " BYN/" +
                    (Bid.lots.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === Bid.lots.mass_max)
                          .name
                      : "Nan") +
                    " - " +
                    Bid.lots.price_max +
                    " BYN/" +
                    (Bid.lots.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === Bid.lots.mass_max)
                          .name
                      : "Nan")
                  }}
                </div>
                <div class="card-left-waste-quantity-cost">
                  {{ Bid.lots.mass_min }} {{
                    Bid.lots.mass_max != 0
                      ? pss93UnitsInfoList.find((obj) => obj.id === Bid.lots.mass_max)
                          .name
                      : "Nan"
                  }}
                </div>

                <div class="card-left-waste-quantity-total">
                  <div class="card-left-waste-quantity-total-descr">Итого:</div>
                  <div class="card-left-waste-quantity-total-price">
                    {{
                      Math.round(Bid.lots.price_min * Bid.lots.mass_min*1000)/1000 +
                      " - " +
                      Math.round(Bid.lots.price_max * Bid.lots.mass_min*1000)/1000
                    }}
                  </div>
                  <div class="card-left-waste-quantity-total-currency">BYN</div>
                  <div class="card-left-waste-quantity-total-tax">
                    {{ Bid.lots.nds == true ? "с НДС" : "без НДС" }}
                  </div>
                </div>
                <div>
                  <!-- {{ delivery.find((obj) => obj.id === lot.disposal) }}
                  {{ delivery }} -->
                </div>
              </div>
            </div>
          </el-col>
          <el-col span="8" class="received-applications">
            <div class="card-left-waste-info-name-received-applications">Мое предложение:</div>
            <div class="card-left-waste-quantity-cost-received"><span class="card-left-waste-quantity-cost-received-text">Масса отхода:</span>
              <span class="card-left-waste-quantity-cost-received-text-numbers">
            {{ Bid.mass }} {{
                            Bid.lots.mass_max != 0
                              ? pss93UnitsInfoList.find((obj) => obj.id === Bid.lots.mass_max)
                                  .name
                              : "Nan"
                          }}
                          </span>
            </div>
                <div class="card-left-waste-quantity-cost-received-mass" ><span class="card-left-waste-quantity-cost-received-text">Стоимость отхода:</span>
                  <span class="card-left-waste-quantity-cost-received-text-numbers">
                 {{
                            Bid.price +
                            " BYN/" +
                            (Bid.lots.mass_max != 0
                              ? pss93UnitsInfoList.find((obj) => obj.id === Bid.lots.mass_max)
                                  .name
                              : "Nan")
                  }}
                  </span>
            </div>
          </el-col>
        </el-row>
      </el-card>
    </div>
    </div>
</template>
<script>
import { mapGetters } from "vuex";
//import SearchBlock from "@/components/searchBlock";
export default {
    name: "wasteExchangeBed",
  //components: { SearchBlock },
  data() {
    return {
        pss93UnitsInfoList: [{ id: null, name: null }],
        ListBid:[{
            cansel:null,
            done:null,
            id:null,
            lots_id:null,
            mass:null,
            price:null,
            user_id:null,
            lots:
            {
                adress: null,
                date_delet: null,
                date_from: null,
                date_to: null,
                delet: false,
                disposal: null,
                done: false,
                lotsId: null,
                mass_max: null,
                mass_min: null,
                nds: false,
                price_max: null,
                price_min: null,
                reason_delet: null,
                text: null,
                user_id: null,
                waste_code: null,
                waste_name: null,
            },
        }]


        };
  },
  computed: {
    ...mapGetters({
      showModal: "GETSHOWMODAL",
      ss01CodeList0: "GETSSS01CODELIST",
      wasteNames0: "GETWASTENAMES",
    }),
  },
  methods: {
    async getSubClassifierById(id) {
      let data = {
        id: id,
        size: 100,
        page: 0,
      };
      return await this.$store.dispatch(
        "getAllDataSubClassifierForSelect",
        data
      );
    },
    LotBidCansel(Bid){
      this.$store.dispatch("LotBidCansel",Bid).then(() => {
        this.notification("Успешно", "Заявка отменена", "success");
        this.$store.dispatch("getMainBid").then((response) => {
        this.ListBid = response.data;
      });
      });
    },
  },
  mounted() {
    this.$store.dispatch(
        "getMainBid"
      ).then((response) => {
      this.ListBid = response.data;
    });
    this.getSubClassifierById(93).then((response) => {
      this.pss93UnitsInfoList = response.data.content;
    });
  },

}
</script>

<style scoped>
.test111{
  display: flex;
  /* align-items: center; */
}
.info{
width: 60%;
}
.lots-search {
  display: flex;
  align-items: center;
}
.lots-sort {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 30px;
  margin-top: 20px;
}
.lots-search h4,
.lots-sort h4 {
  margin-right: 10px;
}

.lots-sort select {
  width: 10vw;
  height: 20px;
  margin-right: 10px;
  border-radius: 2px;
  border: 1px solid #41bf2d;
}
.lots-sort select:hover {
  cursor: pointer;
  border: 2px solid #53e340;
}
.lots-search input:hover {
  cursor: pointer;
  border: 3px solid #53e340;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-header span {
  font-weight: 400;
  font-weight: bold;
  text-decoration: underline;
}
.card-header div {
  font-weight: 400;
  font-weight: bold;
}
.text {
  font-size: 14px;
}

.item {
  margin-bottom: 18px;
}

.box-card {
  width: 100%;
  cursor: pointer;
}

/* .card {
  display: flex;
  justify-content: space-between;
  align-items: center;
} */
.card-left {
  width: 80vw;
}
.card-right {
  width: 20vw;
}
.card-left-company-info {
  width: 40vw;
  display: flex;
  justify-content: space-between;
}
.card-left-waste-info-name,
.card-left-waste-quantity-total-descr {
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 10px;
}
.card-left-waste-info-name {
  padding-top: 20px;
  padding-bottom: 20px;
}
.card-left {
  display: flex;
}
.card-left-company {
  color: #000;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-right: 20px;
}
.card-left-location,
.card-left-waste-quantity-all-waste,
.card-left-waste-quantity-cost {
  color: #a5a5a5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.card-left-waste-quantity {
  width: 50vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.card-left-waste-quantity-total {
  display: flex;
  align-items: center;
}
.card-left-waste-quantity-total-price,
.card-left-waste-quantity-total-currency,
.card-left-waste-quantity-total-tax {
  color: #a5a5a5;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding-right: 5px;
}
.cards {
  margin-bottom: 20px;
}
.add-offer {
  display: flex;
  justify-content: center;
}
.offer-card {
  width: 60vw;
  cursor: pointer;
}
/* стили за для карточки */
.input-waste-name {
  width: 30vw;
  margin-right: 10px;
}
.date {
  width: 20vw;
  margin-right: 10px;
}
.input-waste-code {
  width: 10vw;
  margin-right: 10px;
}

.off-price {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.off-price-checkbox {
  display: flex;
  flex-direction: column;
}
.date {
  width: 50vw;
  display: flex;
  align-items: center;
}
.dateIn {
  margin-bottom: 10px;

  margin-right: 10px;
}
.input-waste-compound {
  width: 50vw;
}
.delivery {
  width: 50vw;
}
.contact {
  display: flex;
  align-items: center;
}
.contact p {
  font-weight: bold;
  margin-right: 5px;
}
.public-offer {
  display: flex;
  justify-content: center;
}
.received-applications{

  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.card-left-waste-info-name-received-applications{
  font-weight: bold;
  font-size: 18px;
}
.card-left-waste-quantity-cost-received-text{
  font-size: 16px;
    font-weight: 400;
}
.card-left-waste-quantity-cost-received-text-numbers{
  color: #a5a5a5;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    padding-right: 5px;
}
</style>

